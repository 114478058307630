<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <button class="close-button" @click="$emit('close')">
              <font-awesome-icon icon="fa-solid fa-xmark" />
            </button>
            <div>
              <slot name="header">
                <h2>{{ $t("verify.verifyLabel") }}</h2>
              </slot>
            </div>
            <div class="modal-body">
              <slot name="body">
                {{ $t("verify.otpDescriptionLabel") }}
                <form @submit.prevent="verifyMail">
                  <input
                    name="otpCode"
                    class="otp-code-input"
                    :class="{ error: errorOTPInput }"
                    type="text"
                    :placeholder="$t('verify.otpCodeTextfieldPlaceholder')"
                    v-model="otpCode"
                  />
                  <button type="submit">
                    {{ $t("verify.checkCodeBtnLabel") }}
                  </button>
                </form>
              </slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <p class="error-msg" v-if="showInputErrorMessage">
                  {{ $t("errorMsgs.inputErrorMessage") }}
                </p>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { PostboxAPI } from "@/api/api";
import { VerifyAccountResponse } from "@/types";
import { defineComponent } from "vue";

export default defineComponent({
  name: "VerifyModal",
  data() {
    return {
      otpCode: "",
      userToken: "",
      errorOTPInput: false,
      showInputErrorMessage: false,
    };
  },
  onMounted() {
    if (
      localStorage.getItem("token") != null &&
      localStorage.getItem("token") != ""
    ) {
      this.userToken = localStorage.getItem("token") as string;
    }
  },
  methods: {
    async verifyMail() {
      const postboxAPI = new PostboxAPI();
      try {
        const response: VerifyAccountResponse = await postboxAPI.verifyAccount(
          localStorage.getItem("token") as string,
          this.otpCode
        );
        if (response.status === "success") {
          this.$emit("close");
          this.$router.push("/");
        } else {
          this.errorOTPInput = true;
          this.showInputErrorMessage = true;
        }
      } catch (error) {
        console.error(error);
        this.errorOTPInput = true;
        this.showInputErrorMessage = true;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.otp-code-input {
  margin: 20px;
  text-align: center;
  width: 50%;
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
