import {
  SignUpBody,
  SingUpResponse,
  SignInBody,
  SignInResponse,
  VerifyAccountBody,
  VerifyAccountResponse,
  ForgotPasswordBody,
  ForgotPasswordResponse,
  ContentListResponse,
  ContentDetailBody,
  ContentDetailResponse,
  DownloadMessageContentResponse,
  DeleteMessageResponse,
  ChangePasswordResponse,
  EditProfileResponse,
  ContentDeleteBody,
} from "@/types";

export class PostboxAPI {
  private _baseUrl: string;
  private _apiKey: string;
  constructor() {
    this._baseUrl = process.env.VUE_APP_BASE_URL;
    this._apiKey = process.env.VUE_APP_API_KEY;
  }

  public async signUp(
    userName: string,
    surName: string,
    email: string,
    password: string,
    dob: string,
    deviceType: string
  ): Promise<SingUpResponse> {
    const requestBody = {
      userName: userName,
      surName: surName,
      email: email,
      password: password,
      dob: dob,
      deviceType: deviceType,
    } as SignUpBody;
    const response = await fetch(this._baseUrl + "/users/signUp", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": this._apiKey,
      },
      body: JSON.stringify(requestBody),
    });
    const responseBody = await response.json();
    try {
      localStorage.token = await responseBody.data.token;
    } catch (e) {
      localStorage.token = "";
    }
    return responseBody;
  }

  public async verifyAccount(
    token_id: string,
    otp: string
  ): Promise<VerifyAccountResponse> {
    const requestBody = {
      token_id: token_id,
      otp: otp,
    } as VerifyAccountBody;
    const response = await fetch(this._baseUrl + "/users/verify-account", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": this._apiKey,
      },
      body: JSON.stringify(requestBody),
    });
    const responseBody = await response.json();
    return responseBody;
  }

  public async signIn(
    email: string,
    password: string
  ): Promise<SignInResponse> {
    const requestBody = {
      email: email,
      password: password,
    } as SignInBody;
    const response = await fetch(this._baseUrl + "/users/signIn", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": this._apiKey,
      },
      body: JSON.stringify(requestBody),
    });
    const responseBody = await response.json();
    try {
      localStorage.token = await responseBody.data.token;
    } catch (e) {
      localStorage.removeItem("token");
    }
    return responseBody;
  }

  public async logout() {
    // Since there is no logout Route in the API, removing the token from the localStorage is sufficient
    localStorage.removeItem("token");
  }

  public async forgotPassword(email: string): Promise<ForgotPasswordResponse> {
    const requestBody = {
      email: email,
    } as ForgotPasswordBody;
    const response = await fetch(this._baseUrl + "/users/forgot-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": this._apiKey,
      },
      body: JSON.stringify(requestBody),
    });
    const responseBody = await response.json();
    return responseBody;
  }

  public async getContentList(): Promise<ContentListResponse> {
    const response = await fetch(this._baseUrl + "/content/get-your-content", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": this._apiKey,
        Authorization: `Bearer ${localStorage.token}`,
      },
    });
    const responseBody = await response.json();
    return responseBody;
  }

  public async getContentDetail(id: string): Promise<ContentDetailResponse> {
    const requestBody = {
      ContentId: id,
    } as ContentDetailBody;
    const response = await fetch(
      this._baseUrl + "/content/get-your-content-detail",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": this._apiKey,
          Authorization: `Bearer ${localStorage.token}`,
        },
        body: JSON.stringify(requestBody),
      }
    );
    const responseBody = await response.json();
    return responseBody;
  }

  public async getSenderProfilePic(content_id: string): Promise<string> {
    const response = await fetch(
      `${this._baseUrl}/content/get-your-content/${content_id}/profileImage`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": this._apiKey,
          Authorization: `Bearer ${localStorage.token}`,
        },
      }
    );
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    return url;
  }

  public async getMessageContent(content_id: string): Promise<string | null> {
    const mediaUrl = `${this._baseUrl}/content/get-your-content/${content_id}/content`;
    const response = await fetch(mediaUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": this._apiKey,
        Authorization: `Bearer ${localStorage.token}`,
        Range: "bytes=0-",
      },
    });

    const contentType = response.headers.get("Content-Type");

    if (
      contentType?.startsWith("image/") ||
      contentType?.startsWith("video/") ||
      contentType?.startsWith("audio/")
    ) {
      if (response.ok) {
        const blob = await response.blob();
        return URL.createObjectURL(blob);
      }
    }
    return null;
  }

  public async downloadMessageContent(
    content_id: string
  ): Promise<DownloadMessageContentResponse> {
    const requestBody = {
      ContentId: content_id,
    } as ContentDetailBody;
    const response = await fetch(this._baseUrl + "/content/download", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": this._apiKey,
        Authorization: `Bearer ${localStorage.token}`,
      },
      body: JSON.stringify(requestBody),
    });

    const responseBody = await response.json();
    return responseBody;
  }

  public async deleteMessageFromPostbox(
    content_id: string
  ): Promise<DeleteMessageResponse> {
    const requestBody = {
      contentId: content_id,
    } as ContentDeleteBody;
    const response = await fetch(this._baseUrl + "/content/your-content", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": this._apiKey,
        Authorization: `Bearer ${localStorage.token}`,
      },
      body: JSON.stringify(requestBody),
    });
    const responseBody = await response.json();
    return responseBody;
  }
  public async changeUserMailAddress(
    newEmail: string
  ): Promise<EditProfileResponse> {
    const requestBody = {
      email: newEmail,
    };
    const response = await fetch(this._baseUrl + "/profile/edit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": this._apiKey,
        Authorization: `Bearer ${localStorage.token}`,
      },
      body: JSON.stringify(requestBody),
    });
    console.log(response);
    const responseBody = await response.json();
    return responseBody;
  }

  public async changeUserPassword(
    oldPassword: string,
    newPassword: string
  ): Promise<ChangePasswordResponse> {
    const requestBody = {
      old_password: oldPassword,
      new_password: newPassword,
    };
    const response = await fetch(this._baseUrl + "/profile/change-password", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": this._apiKey,
        Authorization: `Bearer ${localStorage.token}`,
      },
      body: JSON.stringify(requestBody),
    });
    const responseBody = await response.json();
    return responseBody;
  }
}
