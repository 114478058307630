import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-edf251d8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "postbox" }
const _hoisted_2 = { class: "postbox-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SiteHeader = _resolveComponent("SiteHeader")!
  const _component_PostboxList = _resolveComponent("PostboxList")!
  const _component_SiteFooter = _resolveComponent("SiteFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SiteHeader),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t("postbox.welcomeLabel")), 1),
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_PostboxList)
      ])
    ]),
    _createVNode(_component_SiteFooter)
  ], 64))
}