<template>
  <SiteHeader />
  <div class="login">
    <h1>{{ $t("login.welcomeLabel") }}</h1>
    <div class="login-view-text">
      <p>{{ $t("login.descriptionLabel") }}</p>
    </div>
    <form @submit.prevent="login">
      <input
        type="text"
        name="email"
        :class="{ error: showMailInputErrorMessage }"
        :placeholder="$t('login.emailTextfieldPlaceholder')"
        v-model="email"
      />

      <br />
      <p class="error-msg" v-if="showMailInputErrorMessage">
        {{ emailErrorMsg }}
      </p>

      <br />
      <input
        name="password"
        type="password"
        :class="{ error: showPasswordInputErrorMessage }"
        :placeholder="$t('login.passwordTextfieldPlaceholder')"
        v-model="password"
      />

      <br />

      <p class="error-msg" v-if="showPasswordInputErrorMessage">
        {{ $t("errorMsgs.passwordInputErrorMessage") }}
      </p>
      <br />
      <button type="submit">
        {{ $t("login.loginButtonLabel") }}
      </button>
    </form>
    <p class="error-msg" v-if="showErrorMessage">
      {{ $t("errorMsgs.genericErrorMessage") }}
    </p>
    <div>
      <p class="forgot-pw-ref" @click="showForgotPasswordModal = true">
        {{ $t("login.forgotPasswordLabel") }}
      </p>
    </div>

    <div class="register-referal">
      <div>{{ $t("login.registerNow") }}</div>
      <div @click="$router.push('/register')" class="register-ref-link">
        {{ $t("login.registerButtonLabel") }}
      </div>
    </div>
  </div>
  <SiteFooter />
  <ForgotPasswordModal
    v-if="showForgotPasswordModal"
    @close="showForgotPasswordModal = false"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { version } from "../../package.json";
import { PostboxAPI } from "@/api/api";
import { SignInResponse } from "@/types";
import SiteHeader from "@/components/SiteHeader.vue";
import SiteFooter from "@/components/SiteFooter.vue";
import ForgotPasswordModal from "@/components/ForgotPasswordModal.vue";

export default defineComponent({
  name: "LoginView",
  components: {
    SiteHeader,
    SiteFooter,
    ForgotPasswordModal,
  },
  data() {
    return {
      email: "",
      password: "",
      showForgotPasswordModal: false,
      showPasswordInputErrorMessage: false,
      showErrorMessage: false,
      showMailInputErrorMessage: false,
      emailErrorMsg: "",
    };
  },
  methods: {
    login() {
      const postboxAPI = new PostboxAPI();
      try {
        postboxAPI
          .signIn(this.email, this.password)
          .then((response: SignInResponse) => {
            if (response.status === "success") {
              this.$router.push("/");
              this.showErrorMessage = false;
              this.showPasswordInputErrorMessage = false;
              this.showMailInputErrorMessage = false;
            } else if (
              response.status === "failed" &&
              response.message === "Password not match"
            ) {
              this.showPasswordInputErrorMessage = true;
              this.showErrorMessage = false;
              this.showMailInputErrorMessage = false;
            } else if (
              response.status === "failed" &&
              response.message === "Email not match"
            ) {
              this.showMailInputErrorMessage = true;
              this.showErrorMessage = false;
              this.showPasswordInputErrorMessage = false;
              this.emailErrorMsg = this.$t("errorMsgs.emailInputErrorMessage");
            } else if (
              response.status === "failed" &&
              response.message === '"email" must be a valid email'
            ) {
              this.showErrorMessage = false;
              this.showPasswordInputErrorMessage = false;
              this.showMailInputErrorMessage = true;
              this.emailErrorMsg = this.$t(
                "errorMsgs.emailInvalidErrorMessage"
              );
            } else if (
              response.status === "failed" &&
              response.message === '"email" is not allowed to be empty'
            ) {
              this.showErrorMessage = false;
              this.showPasswordInputErrorMessage = false;
              this.showMailInputErrorMessage = true;
              this.emailErrorMsg = this.$t("errorMsgs.noEmailErrorMessage");
            } else {
              this.showErrorMessage = true;
              this.showPasswordInputErrorMessage = false;
              this.showMailInputErrorMessage = false;
            }
          });
      } catch (error) {
        console.error(error);
        this.showErrorMessage = true;
        this.showPasswordInputErrorMessage = false;
        this.showMailInputErrorMessage = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../assets/scss/_typography.scss";
@import "../assets/scss/_colors.scss";

.login {
  min-height: calc(100vh - 300px);
}
.login-view-text {
  margin: 50px;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}
.login-information-text {
  margin: 0px 20px 20px 20px;
  font-size: 1.2rem;
  color: red;
}

.forgot-pw-ref:hover {
  color: $accent-color;
  cursor: pointer;
}
.register-referal {
  margin-top: 50px;
  color: $accent-color;
}
.register-ref-link:hover {
  color: $primary-color;
  cursor: pointer;
}

@media screen and (max-width: 700px) {
  .login-view-text {
    width: 80%;
  }
}
</style>
