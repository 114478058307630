<template>
  <div class="locale-changer">
    <font-awesome-icon icon="fa-solid fa-globe" />
    <select v-model="language" @change="changeLanguage()" class="lang-select">
      <option value="de">DE</option>
      <option value="en">EN</option>
    </select>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import i18next from "i18next";

export default defineComponent({
  name: "LanguageSwitcher",
  data() {
    return {
      language: localStorage.getItem("language") || "de",
    };
  },
  methods: {
    changeLanguage() {
      localStorage.setItem("language", this.language);
      i18next.changeLanguage(this.language);
    },
  },
  mounted() {
    this.changeLanguage();
  },
});
</script>

<style lang="scss" scoped>
@import "../assets/scss/_colors.scss";
@import "../assets/scss/_typography.scss";

.lang-select {
  border: none;
  font-family: Futura EF Book;
  font-size: $body-text-font-size;
  color: $menu-button-color;
  background-color: transparent;
}

.locale-changer svg {
  color: $menu-button-color;
  margin-right: 10px;
  height: auto;
  width: 18px;
}

.lang-select:focus {
  outline: none;
}

.lang-select:hover {
  cursor: pointer;
  color: $accent-color;
}
</style>
