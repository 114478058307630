<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <button class="close-button" @click="$emit('close')">
              <font-awesome-icon icon="fa-solid fa-xmark" />
            </button>
            <div>
              <slot name="header">
                <h2>{{ title }}</h2>
              </slot>
            </div>
            <div class="modal-body">
              <slot name="body">
                <p>{{ message }}</p>
              </slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button @click="$emit('close')">OK</button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ErrorStatusModal",
  props: {
    message: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
  },
});
</script>
