<template>
  <div class="header-wrapper">
    <div class="header-container">
      <a href="https://forevermind.de/"
        ><img
          src="../assets/logo_forevermind_color.png"
          alt="Forevermind Logo"
          class="logo"
      /></a>
      <div class="app-store-btns">
        <a
          href="https://apps.apple.com/de/app/forevermind/id6450253912"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="../assets/app-store-badge.svg"
            alt="Download on the App Store"
            class="app-store-badge"
          />
        </a>
        <!-- <a -->
        <!-- href="https://play.google.com/store/apps/..." -->
        <!-- target="_blank" -->
        <!-- rel="noopener noreferrer" -->
        <!-- > -->
        <!-- <img -->
        <!-- src="../assets/google-play-badge.png" -->
        <!-- alt="Get it on Google Play" -->
        <!-- class="google-play-badge" -->
        <!-- /> -->
        <!-- </a> -->
      </div>
      <div class="menu-buttons">
        <LanguageSwitcher />

        <button
          v-if="userToken != null"
          @click="handleLogout"
          :title="$t('siteHeader.logoutButtonLabel')"
        >
          {{ $t("siteHeader.logoutButtonLabel") }}
        </button>
        <button
          class="settings-btn"
          v-if="userToken != null"
          @click="showSettingsModal = !showSettingsModal"
          :title="$t('siteHeader.settingsButtonLabel')"
        >
          <font-awesome-icon icon="fa-solid fa-gear" />
        </button>
      </div>
      <!--  -->
      <div id="mobile-sidemenu">
        <button
          class="mobile-sidemenu-btn"
          v-on:click="navOpen = !navOpen"
          v-bind:class="{ active: navOpen }"
        >
          <font-awesome-icon icon="fa-solid fa-bars" />
        </button>
        <transition name="translateX">
          <nav v-show="navOpen">
            <button
              class="mobile-sidemenu-close-btn"
              v-on:click="navOpen = !navOpen"
              v-bind:class="{ active: navOpen }"
            >
              <font-awesome-icon icon="fa-solid fa-xmark" />
            </button>
            <div class="mobile-sidemenu-wrapper">
              <div class="mobile-menu-buttons">
                <LanguageSwitcher />
                <button
                  class="settings-btn"
                  v-if="userToken != null"
                  @click="showSettingsModal = !showSettingsModal"
                  :title="$t('siteHeader.settingsButtonLabel')"
                >
                  <font-awesome-icon icon="fa-solid fa-gear" />Einstellungen
                </button>
                <button
                  v-if="userToken != null"
                  @click="handleLogout"
                  :title="$t('siteHeader.logoutButtonLabel')"
                >
                  {{ $t("siteHeader.logoutButtonLabel") }}
                </button>
              </div>

              <div class="app-store-btns-mobile">
                <a
                  href="https://apps.apple.com/de/app/forevermind/id6450253912"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="../assets/app-store-badge.svg"
                    alt="Download on the App Store"
                    class="app-store-badge"
                  />
                </a>
                <!-- <a -->
                <!-- href="https://play.google.com/store/apps/..." -->
                <!-- target="_blank" -->
                <!-- rel="noopener noreferrer" -->
                <!-- > -->
                <!-- <img -->
                <!-- src="../assets/google-play-badge.png" -->
                <!-- alt="Get it on Google Play" -->
                <!-- class="google-play-badge" -->
                <!-- /> -->
                <!-- </a> -->
              </div>
            </div>
          </nav>
        </transition>
      </div>
      <!--  -->
    </div>
  </div>
  <AccountSettingsModal
    v-if="showSettingsModal"
    @close="showSettingsModal = false"
  ></AccountSettingsModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PostboxAPI } from "@/api/api";
import router from "@/router";
import AccountSettingsModal from "./AccountSettingsModal.vue";
import LanguageSwitcher from "./LanguageSwitcher.vue";

const postboxAPI = new PostboxAPI();

export default defineComponent({
  name: "SiteHeader",
  data() {
    return {
      showSettingsModal: false,
      hover: false,
      userToken: localStorage.token,
      navOpen: false,
    };
  },
  methods: {
    async handleLogout() {
      await postboxAPI.logout();
      router.push("/login");
    },
  },
  components: { AccountSettingsModal, LanguageSwitcher },
});
</script>

<style lang="scss" scoped>
@import "../assets/scss/_colors.scss";
@import "../assets/scss/_typography.scss";
.header-wrapper {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 90px;
  box-shadow: 0px 10px 10px $disabled-button-color;
  z-index: 100;
  background-color: $button-text-color;
}
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-content: center;
  padding: 20px;
}

.logo {
  height: 50px;
  margin-right: 13vw;
}

.button-icon {
  margin-right: 5px;
  font-size: 1rem;
}

.settings-btn {
  color: $menu-button-color;
  background-color: transparent;
  padding: 0px;
}

.settings-btn:hover {
  cursor: pointer;
  color: $accent-color;
}

.menu-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

#mobile-sidemenu {
  display: none;
  nav {
    width: 250px;
    height: 100vh;
    background: $button-text-color;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99;
    box-shadow: 10px 20px 20px $disabled-button-color;
  }

  .mobile-sidemenu-close-btn {
    color: $menu-button-color;
    background-color: transparent;
    cursor: pointer;
    padding: 20px;
    position: fixed;
    top: 0;
    right: 0;
    font-size: 25px;
  }
  .mobile-sidemenu-close-btn:hover {
    color: $accent-color;
  }

  .mobile-sidemenu-btn {
    color: $menu-button-color;
    background-color: transparent;
    cursor: pointer;
    padding: 10px;
  }
  .mobile-sidemenu-btn:hover {
    color: $accent-color;
  }
  .mobile-sidemenu-wrapper {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 70%;
  }
}

.translateX-enter {
  transform: translateX(200px);
  opacity: 0;
}

.translateX-enter-active,
.translateX-leave-active {
  transform-origin: top right 0;
  transition: 1s ease-in-out;
}

.translateX-leave-to {
  transform: translateX(200px);
  opacity: 0;
}

//

@media only screen and (max-width: 1200px) {
  #mobile-sidemenu {
    display: block;
  }
  .header-container {
    padding: 15px;
  }

  .logo {
    height: 40px;
    margin-right: 0px;
  }

  .menu-buttons {
    display: none;
  }

  .app-store-btns {
    display: none;
  }

  .settings-btn svg {
    padding-right: 10px;
  }
}
</style>
