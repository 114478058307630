import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1bcb95df"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-mask" }
const _hoisted_2 = { class: "modal-wrapper" }
const _hoisted_3 = { class: "modal-container" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = { type: "submit" }
const _hoisted_7 = { class: "modal-footer" }
const _hoisted_8 = {
  key: 0,
  class: "error-msg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_Transition, { name: "modal" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("button", {
                class: "close-button",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
              }, [
                _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-xmark" })
              ]),
              _createElementVNode("div", null, [
                _renderSlot(_ctx.$slots, "header", {}, () => [
                  _createElementVNode("h2", null, _toDisplayString(_ctx.$t("login.forgotPasswordLabel")), 1)
                ], true)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "body", {}, () => [
                  _createTextVNode(_toDisplayString(_ctx.$t("login.forgotPasswordDescription")) + " ", 1),
                  _createElementVNode("form", {
                    onSubmit: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.forgotPassword && _ctx.forgotPassword(...args)), ["prevent"]))
                  }, [
                    _withDirectives(_createElementVNode("input", {
                      name: "email",
                      class: "mail-input",
                      type: "text",
                      placeholder: _ctx.$t('login.emailTextfieldPlaceholder'),
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.email) = $event))
                    }, null, 8, _hoisted_5), [
                      [_vModelText, _ctx.email]
                    ]),
                    _createElementVNode("button", _hoisted_6, _toDisplayString(_ctx.$t("login.newPasswordBtnLabel")), 1)
                  ], 32)
                ], true)
              ]),
              _createElementVNode("div", _hoisted_7, [
                _renderSlot(_ctx.$slots, "footer", {}, () => [
                  (_ctx.showErrorMessage)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.errorMsg), 1))
                    : _createCommentVNode("", true)
                ], true)
              ])
            ])
          ])
        ])
      ]),
      _: 3
    })
  ]))
}