import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7540e79c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal-mask" }
const _hoisted_2 = { class: "modal-wrapper" }
const _hoisted_3 = { class: "modal-container" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = { class: "settings-item" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = ["placeholder"]
const _hoisted_8 = {
  type: "submit",
  class: "save-btn"
}
const _hoisted_9 = { class: "modal-footer" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 2,
  class: "error-msg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_Transition, { name: "modal" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("button", {
                class: "close-button",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
              }, [
                _createVNode(_component_font_awesome_icon, { icon: "fa-solid fa-xmark" })
              ]),
              _createElementVNode("div", null, [
                _renderSlot(_ctx.$slots, "header", {}, () => [
                  _createElementVNode("h2", null, _toDisplayString(_ctx.$t("settingsModal.headingLabel")), 1)
                ], true)
              ]),
              _createElementVNode("div", _hoisted_4, [
                _renderSlot(_ctx.$slots, "body", {}, () => [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("h3", null, _toDisplayString(_ctx.$t("settingsModal.changePasswordLabel")), 1),
                    _createElementVNode("form", {
                      onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.changePassword && _ctx.changePassword(...args)), ["prevent"]))
                    }, [
                      _withDirectives(_createElementVNode("input", {
                        name: "oldPassword",
                        class: _normalizeClass({ error: _ctx.errorPasswordInput }),
                        type: "password",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.oldPassword) = $event)),
                        placeholder: _ctx.$t('settingsModal.oldPasswordLabel')
                      }, null, 10, _hoisted_6), [
                        [_vModelText, _ctx.oldPassword]
                      ]),
                      _withDirectives(_createElementVNode("input", {
                        name: "newPassword",
                        class: _normalizeClass({ error: _ctx.errorPasswordInput }),
                        type: "password",
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newPassword) = $event)),
                        placeholder: _ctx.$t('settingsModal.newPasswordLabel')
                      }, null, 10, _hoisted_7), [
                        [_vModelText, _ctx.newPassword]
                      ]),
                      _createElementVNode("button", _hoisted_8, _toDisplayString(_ctx.$t("settingsModal.saveButtonLabel")), 1)
                    ], 32)
                  ])
                ], true)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _renderSlot(_ctx.$slots, "footer", {}, () => [
                  (_ctx.showMailChangeSuccess)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.$t("settingsModal.mailChangeSuccessLabel")), 1))
                    : _createCommentVNode("", true),
                  (_ctx.showPasswordChangeSuccess)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.$t("settingsModal.passwordChangeSuccessLabel")), 1))
                    : _createCommentVNode("", true),
                  (_ctx.showInputErrorMessage)
                    ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.$t("errorMsgs.inputErrorMessage")), 1))
                    : _createCommentVNode("", true)
                ], true)
              ])
            ])
          ])
        ])
      ]),
      _: 3
    })
  ]))
}