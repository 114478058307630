<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <button class="close-button" @click="$emit('close')">
              <font-awesome-icon icon="fa-solid fa-xmark" />
            </button>
            <div>
              <slot name="header">
                <h2>{{ $t("settingsModal.headingLabel") }}</h2>
              </slot>
            </div>
            <div class="modal-body">
              <slot name="body">
                <!-- Change email option remains hidden until api endpoint is fixed -->
                <!-- <div class="settings-container">
                  <div class="settings-item">
                    <h3>{{ $t("settingsModal.changeMailLabel") }}</h3>
                    <form @submit.prevent="changeEmail">
                      <input
                        name="email"
                        :class="{ error: errorMailInput }"
                        type="text"
                        v-model="email"
                        :placeholder="$t('settingsModal.newMailLabel')"
                      />

                      <button
                        type="submit"
                        class="save-btn"
                        @click="changeEmail()"
                      >
                        {{ $t("settingsModal.saveButtonLabel") }}
                      </button>
                    </form>
                  </div> -->
                <div class="settings-item">
                  <h3>{{ $t("settingsModal.changePasswordLabel") }}</h3>
                  <form @submit.prevent="changePassword">
                    <input
                      name="oldPassword"
                      :class="{ error: errorPasswordInput }"
                      type="password"
                      v-model="oldPassword"
                      :placeholder="$t('settingsModal.oldPasswordLabel')"
                    />
                    <input
                      name="newPassword"
                      :class="{ error: errorPasswordInput }"
                      type="password"
                      v-model="newPassword"
                      :placeholder="$t('settingsModal.newPasswordLabel')"
                    />
                    <button type="submit" class="save-btn">
                      {{ $t("settingsModal.saveButtonLabel") }}
                    </button>
                  </form>
                </div>
              </slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <p v-if="showMailChangeSuccess">
                  {{ $t("settingsModal.mailChangeSuccessLabel") }}
                </p>
                <p v-if="showPasswordChangeSuccess">
                  {{ $t("settingsModal.passwordChangeSuccessLabel") }}
                </p>
                <p class="error-msg" v-if="showInputErrorMessage">
                  {{ $t("errorMsgs.inputErrorMessage") }}
                </p>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PostboxAPI } from "@/api/api";

const postboxAPI = new PostboxAPI();

export default defineComponent({
  name: "AccountSettingsModal",
  data() {
    return {
      userToken: localStorage.token,
      email: "",
      oldPassword: "",
      newPassword: "",
      showMailChangeSuccess: false,
      showPasswordChangeSuccess: false,
      showInputErrorMessage: false,
      errorMailInput: false,
      errorPasswordInput: false,
    };
  },

  methods: {
    async changeEmail() {
      if (this.email) {
        try {
          const response = await postboxAPI.changeUserMailAddress(this.email);
          if (response.status == "success") {
            this.showMailChangeSuccess = true;
            this.showInputErrorMessage = false;
            this.errorPasswordInput = false;
          } else {
            this.showInputErrorMessage = true;
            this.errorPasswordInput = true;
          }
        } catch (error) {
          console.error(error);
          this.showInputErrorMessage = true;
          this.errorMailInput = true;
        }
      } else {
        this.showInputErrorMessage = true;
        this.errorMailInput = true;
      }
    },

    async changePassword() {
      if (this.oldPassword && this.newPassword) {
        try {
          const response = await postboxAPI.changeUserPassword(
            this.oldPassword,
            this.newPassword
          );
          if (response.status == "success") {
            this.showPasswordChangeSuccess = true;
            this.showInputErrorMessage = false;
            this.errorPasswordInput = false;
          } else {
            this.showInputErrorMessage = true;
            this.errorPasswordInput = true;
          }
        } catch (error) {
          console.error(error);
          this.showInputErrorMessage = true;
          this.errorPasswordInput = true;
        }
      } else {
        this.showInputErrorMessage = true;
        this.errorPasswordInput = true;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../assets/scss/_colors.scss";
@import "../assets/scss/_typography.scss";
@import "../assets/scss/_fonts.scss";

.input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
</style>
