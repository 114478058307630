import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18next from "i18next";
import I18NextVue from "i18next-vue";
import de from "./locales/de.json";
import en from "./locales/en.json";
import "./assets/scss/main.scss";

i18next.init({
  lng: "de",
  interpolation: {
    escapeValue: false,
  },
  //fallbackLng: "de",
  resources: {
    de: {
      translation: de,
    },
    en: {
      translation: en,
    },
  },
});

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
/* import specific icons */
import {
  faArrowRightFromBracket,
  faEnvelopeOpenText,
  faXmark,
  faGlobe,
  faGear,
  faBars,
  faSpinner,
} from "@fortawesome/free-solid-svg-icons";
/* add icons to the library */
library.add(
  faArrowRightFromBracket,
  faEnvelopeOpenText,
  faXmark,
  faGlobe,
  faGear,
  faBars,
  faSpinner
);

createApp(App)
  .use(store)
  .use(router)
  .use(I18NextVue, { i18next })
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app");
