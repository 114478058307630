<template>
  <SiteHeader />
  <div class="postbox">
    <h1>{{ $t("postbox.welcomeLabel") }}</h1>
    <div class="postbox-list"><PostboxList /></div>
  </div>
  <SiteFooter />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PostboxList from "@/components/PostboxList.vue";
import SiteHeader from "@/components/SiteHeader.vue";
import SiteFooter from "@/components/SiteFooter.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    PostboxList,
    SiteHeader,
    SiteFooter,
  },
  created() {
    if (localStorage.getItem("token") === null) {
      this.$router.push("/login");
    }
  },
});
</script>
<style lang="scss" scoped>
.postbox {
  min-height: calc(100vh - 300px);
}

.postbox-list {
  margin: 0 auto;
  width: 80%;
}

@media screen and (max-width: 650px) {
  .postbox-list {
    width: 90%;
    margin: 0 auto;
  }
}
</style>
