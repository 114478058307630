<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <button class="close-button" @click="$emit('close')">
              <font-awesome-icon icon="fa-solid fa-xmark" />
            </button>
            <div>
              <slot name="header">
                <h2>{{ $t("login.forgotPasswordLabel") }}</h2>
              </slot>
            </div>
            <div class="modal-body">
              <slot name="body">
                {{ $t("login.forgotPasswordDescription") }}
                <form @submit.prevent="forgotPassword">
                  <input
                    name="email"
                    class="mail-input"
                    type="text"
                    :placeholder="$t('login.emailTextfieldPlaceholder')"
                    v-model="email"
                  />
                  <button type="submit">
                    {{ $t("login.newPasswordBtnLabel") }}
                  </button>
                </form>
              </slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <p class="error-msg" v-if="showErrorMessage">
                  {{ errorMsg }}
                </p>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { PostboxAPI } from "@/api/api";
import { ForgotPasswordResponse } from "@/types";
import { defineComponent } from "vue";

export default defineComponent({
  name: "ForgotPasswordModal",
  data() {
    return {
      email: "",
      showErrorMessage: false,
      errorMsg: "",
    };
  },
  methods: {
    async forgotPassword() {
      const postboxAPI = new PostboxAPI();
      try {
        const response: ForgotPasswordResponse =
          await postboxAPI.forgotPassword(this.email);
        if (response.status === "success") {
          this.$emit("close");
        } else if (
          response.status === "failed" &&
          response.message === "Email not match"
        ) {
          this.showErrorMessage = true;
          this.errorMsg = this.$t("errorMsgs.emailInputErrorMessage");
        } else if (
          response.status === "failed" &&
          response.message === '"email" must be a valid email'
        ) {
          this.showErrorMessage = true;
          this.errorMsg = this.$t("errorMsgs.emailInvalidErrorMessage");
        } else if (
          response.status === "failed" &&
          response.message === '"email" is not allowed to be empty'
        ) {
          this.showErrorMessage = true;
          this.errorMsg = this.$t("errorMsgs.noEmailErrorMessage");
        } else {
          this.showErrorMessage = true;
          this.errorMsg = this.$t("errorMsgs.genericErrorMessage");
        }
      } catch (error) {
        console.error(error);
        this.showErrorMessage = true;
        this.errorMsg = this.$t("errorMsgs.genericErrorMessage");
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.mail-input {
  margin: 20px;
  text-align: center;
}

@media screen and (max-width: 700px) {
  .mail-input {
    width: 80%;
    margin: 30px 0px;
  }
}
</style>
