<template>
  <SiteHeader />
  <div class="signup-wrapper">
    <h1>{{ $t("signUp.welcomeLabel") }}</h1>
    <div class="signup-view-text">
      <p>{{ $t("signUp.descriptionLabel") }}</p>
    </div>
    <div class="signup-form">
      <form @submit.prevent="registerUser">
        <input
          name="firstName"
          type="text"
          :class="{ error: showUserNameErrorMessage }"
          :placeholder="$t('signUp.firstNameTextfieldPlaceholder')"
          v-model="userName"
        />
        <br />
        <p class="error-msg" v-if="showUserNameErrorMessage">
          {{ $t("errorMsgs.noUserNameErrorMessage") }}
        </p>
        <br />
        <input
          name="lastName"
          :class="{ error: showSurNameErrorMessage }"
          type="text"
          :placeholder="$t('signUp.lastNameTextfieldPlaceholder')"
          v-model="surName"
        />
        <br />
        <p class="error-msg" v-if="showSurNameErrorMessage">
          {{ $t("errorMsgs.noSurNameErrorMessage") }}
        </p>
        <br />
        <input
          name="email"
          :class="{ error: showEmailErrorMessage }"
          type="text"
          :placeholder="$t('signUp.emailTextfieldPlaceholder')"
          v-model="email"
        />
        <br />
        <p class="error-msg" v-if="showEmailErrorMessage">
          {{ emailErrorMsg }}
        </p>
        <br />
        <input
          name="password"
          :class="{ error: showPasswordErrorMessage }"
          type="password"
          :placeholder="$t('signUp.passwordTextfieldPlaceholder')"
          v-model="password"
        />
        <br />
        <p class="error-msg" v-if="showPasswordErrorMessage">
          {{ $t("errorMsgs.noPasswordErrorMessage") }}
        </p>
        <br />
        <button type="submit">
          {{ $t("signUp.registerLabel") }}
        </button>
      </form>
      <p class="error-msg" v-if="showErrorMessage">
        {{ $t("errorMsgs.genericErrorMessage") }}
      </p>
      <div class="login-referal">
        <div>{{ $t("signUp.loginLabel") }}</div>
        <div @click="$router.push('/login')" class="login-ref-link">
          {{ $t("signUp.loginButtonLabel") }}
        </div>
      </div>
    </div>
  </div>
  <SiteFooter />
  <VerifyModal v-if="showVerifyModal" @close="showVerifyModal = false" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { PostboxAPI } from "@/api/api";
import { SingUpResponse } from "@/types";
import SiteHeader from "@/components/SiteHeader.vue";
import SiteFooter from "@/components/SiteFooter.vue";
import VerifyModal from "@/components/VerifyModal.vue";

export default defineComponent({
  name: "LoginView",
  components: {
    SiteHeader,
    SiteFooter,
    VerifyModal,
  },
  data() {
    return {
      userName: "",
      surName: "",
      email: "",
      password: "",
      dob: "",
      deviceType: "",
      showVerifyModal: false,
      showUserNameErrorMessage: false,
      showSurNameErrorMessage: false,
      showEmailErrorMessage: false,
      showPasswordErrorMessage: false,
      showErrorMessage: false,
      emailErrorMsg: "",
    };
  },
  methods: {
    async registerUser() {
      const postboxAPI = new PostboxAPI();
      try {
        const response: SingUpResponse = await postboxAPI.signUp(
          this.userName,
          this.surName,
          this.email,
          this.password,
          this.dob,
          this.deviceType
        );
        console.log(response);
        if (response.status === "success") {
          this.showVerifyModal = true;
        } else if (
          response.status === "failed" &&
          response.message === '"userName" is not allowed to be empty'
        ) {
          this.showVerifyModal = false;
          this.showUserNameErrorMessage = true;
          this.showSurNameErrorMessage = false;
          this.showEmailErrorMessage = false;
          this.showPasswordErrorMessage = false;
          this.showErrorMessage = false;
        } else if (
          response.status === "failed" &&
          response.message === '"surName" is not allowed to be empty'
        ) {
          this.showVerifyModal = false;
          this.showUserNameErrorMessage = false;
          this.showSurNameErrorMessage = true;
          this.showEmailErrorMessage = false;
          this.showPasswordErrorMessage = false;
          this.showErrorMessage = false;
        } else if (
          response.status === "failed" &&
          response.message === '"email" is not allowed to be empty'
        ) {
          this.showVerifyModal = false;
          this.showUserNameErrorMessage = false;
          this.showSurNameErrorMessage = false;
          this.showEmailErrorMessage = true;
          this.showPasswordErrorMessage = false;
          this.showErrorMessage = false;
          this.emailErrorMsg = this.$t("errorMsgs.noEmailErrorMessage");
        } else if (
          response.status === "failed" &&
          response.message === '"password" is not allowed to be empty'
        ) {
          this.showVerifyModal = false;
          this.showUserNameErrorMessage = false;
          this.showSurNameErrorMessage = false;
          this.showEmailErrorMessage = false;
          this.showPasswordErrorMessage = true;
          this.showErrorMessage = false;
        } else if (
          response.status === "failed" &&
          response.message === "This email already exist"
        ) {
          this.showVerifyModal = false;
          this.showUserNameErrorMessage = true;
          this.showSurNameErrorMessage = false;
          this.showEmailErrorMessage = true;
          this.showPasswordErrorMessage = false;
          this.showErrorMessage = false;
          this.emailErrorMsg = this.$t(
            "errorMsgs.emailAlreadyExistsErrorMessage"
          );
        } else if (
          response.status === "failed" &&
          response.message === '"email" must be a valid email'
        ) {
          this.showVerifyModal = false;
          this.showUserNameErrorMessage = false;
          this.showSurNameErrorMessage = false;
          this.showEmailErrorMessage = true;
          this.showPasswordErrorMessage = false;
          this.showErrorMessage = false;
          this.emailErrorMsg = this.$t("errorMsgs.emailInvalidErrorMessage");
        } else {
          this.showErrorMessage = true;
          this.showVerifyModal = false;
          this.showUserNameErrorMessage = false;
          this.showSurNameErrorMessage = false;
          this.showEmailErrorMessage = false;
          this.showPasswordErrorMessage = false;
        }
      } catch (error) {
        this.showErrorMessage = true;
        this.showVerifyModal = false;
        this.showUserNameErrorMessage = false;
        this.showSurNameErrorMessage = false;
        this.showEmailErrorMessage = false;
        this.showPasswordErrorMessage = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../assets/scss/_colors.scss";
.signup-wrapper {
  min-height: calc(100vh - 300px);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signup-view-text {
  width: 50%;
  text-align: center;
}

.signup-form {
  margin-top: 50px;
  width: 50%;
}
.login-referal {
  margin-top: 50px;
  color: $accent-color;
}
.login-ref-link:hover {
  color: $primary-color;
  cursor: pointer;
}

@media screen and (max-width: 800px) {
  .signup-form {
    width: 90%;
  }
  .signup-view-text {
    width: 80%;
  }
}
</style>
