<template>
  <div>
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <button class="close-button" @click="$emit('close')">
              <font-awesome-icon icon="fa-solid fa-xmark" />
            </button>
            <div>
              <slot name="header">
                <h3>{{ msgContent.Info_Data.Name }}</h3>
                <p class="content-info">{{ msgContentTypeInfo }}</p>
              </slot>
            </div>
            <div class="modal-body">
              <slot name="body">
                <div class="msg-content">
                  <h2>{{ msgContent.Content_Data[0].title }}</h2>

                  <img
                    v-if="
                      mediaUrl &&
                      msgContent.Content_Data[0].contenttype == 'Images'
                    "
                    :src="mediaUrl"
                    alt="{{msgContent.Content_Data[0].title}}"
                    class="media-content"
                  />
                  <video
                    v-else-if="
                      mediaUrl &&
                      msgContent.Content_Data[0].contenttype == 'Video'
                    "
                    :src="mediaUrl"
                    alt="Message type icon"
                    controls
                    class="media-content"
                  ></video>
                  <audio
                    v-else-if="
                      mediaUrl &&
                      msgContent.Content_Data[0].contenttype == 'Audio'
                    "
                    :src="mediaUrl"
                    alt="Message type icon"
                    controls
                  ></audio>
                  <p v-else-if="contentLoadFailed" class="error-msg">
                    {{ $t("errorMsgs.invalidContentLabel") }}
                  </p>
                  <div class="loading-spinner" v-else>
                    <font-awesome-icon icon="fa-solid fa-spinner" spin />
                  </div>
                  <p>"{{ msgContent.Content_Data[0].description }}"</p>
                </div>
              </slot>
            </div>
            <div class="modal-footer">
              <slot name="footer">
                <button @click="downloadMsg()" v-if="!deleteBtnClicked">
                  {{ $t("msgModal.downloadButtonLabel") }}
                </button>

                <button
                  @click="deleteBtnClicked = !deleteBtnClicked"
                  v-if="!deleteBtnClicked"
                >
                  {{ $t("msgModal.deleteButtonLabel") }}
                </button>
                <div class="save-dlt-msg" v-if="deleteBtnClicked">
                  <hr />
                  <p>{{ $t("msgModal.saveDeleteLabel") }}</p>

                  <button class="delete-msg-btn" @click="deleteMsg()">
                    {{ $t("msgModal.saveDeleteBtnLabel") }}
                  </button>
                  <button @click="deleteBtnClicked = !deleteBtnClicked">
                    {{ $t("msgModal.cancelBtnLabel") }}
                  </button>
                </div>
                <transition name="fade">
                  <div class="download-msg" v-if="displayDownloadInfoMsg">
                    <p>{{ downloadInfoLabel }}</p>
                  </div>
                </transition>
                <transition name="fade">
                  <div class="error-msg" v-if="showErrorMsg">
                    <p>{{ $t("errorMsgs.genericErrorMessage") }}</p>
                  </div>
                </transition>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { MessageData } from "@/types";
import { PostboxAPI } from "@/api/api";
import router from "@/router";
const postboxAPI = new PostboxAPI();

export default defineComponent({
  name: "MessageModal",
  data() {
    return {
      msgTypeLabel: "",
      saveMsgInfoLabel: "",
      mediaUrl: null as string | null,
      displayDownloadInfoMsg: false,
      msgContentTypeInfo: "",
      downloadInfoLabel: "",
      fileType: "",
      deleteBtnClicked: false,
      showErrorMsg: false,
      contentLoadFailed: false,
    };
  },
  inject: ["getMessageList"],
  props: {
    msgContent: {
      type: Object as () => MessageData,
      required: true,
    },
    senderProfilePicture: {
      type: String,
      required: false,
    },
  },

  created() {
    this.getMessageContent();
    this.computeMsgTypeValues();
  },

  methods: {
    computeMsgTypeValues() {
      switch (this.msgContent.Content_Data[0].contenttype) {
        case "Images":
          this.downloadInfoLabel = this.$t("msgModal.saveImgInfoLabel");
          this.msgContentTypeInfo = this.$t("msgModal.imageMsgTypeLabel");
          this.fileType = ".jpg";
          break;

        case "Video":
          this.downloadInfoLabel = this.$t("msgModal.saveVideoInfoLabel");
          this.msgContentTypeInfo = this.$t("msgModal.videoMsgTypeLabel");
          this.fileType = ".mp4";
          break;

        case "Text":
          this.downloadInfoLabel = this.$t("msgModal.saveAudioInfoLabel");
          this.msgContentTypeInfo = this.$t("msgModal.textMsgTypeLabel");
          this.fileType = ".txt";
          break;

        case "Audio":
          this.downloadInfoLabel = this.$t("msgModal.saveTextInfoLabel");
          this.msgContentTypeInfo = this.$t("msgModal.audioMsgTypeLabel");
          this.fileType = ".mp3";
          break;

        default:
          this.downloadInfoLabel = this.$t("msgModal.saveTextInfoLabel");
          this.msgContentTypeInfo = this.$t("msgModal.unknownMsgTypeLabel");
          this.fileType = ".txt";
          break;
      }
    },

    async getMessageContent() {
      try {
        this.mediaUrl = await postboxAPI.getMessageContent(
          this.msgContent.Content_Data[0].content_id
        );
      } catch (error) {
        this.contentLoadFailed = true;
        console.error(error);
      }
    },

    async downloadMsg() {
      try {
        const response = await postboxAPI.downloadMessageContent(
          this.msgContent.Content_Data[0].content_id
        );
        if (response.status === "success") {
          this.showErrorMsg = false;
          const link = document.createElement("a");
          if (this.mediaUrl) {
            link.href = this.mediaUrl;
            link.setAttribute(
              "download",
              this.msgContent.Content_Data[0].title + this.fileType
            );
            document.body.appendChild(link);
            link.click();
            this.displayDownloadInfoMsg = true;
          }
        } else {
          this.showErrorMsg = true;
        }
      } catch (error) {
        console.error(error);
        this.showErrorMsg = true;
      }
    },

    async deleteMsg() {
      const result = await postboxAPI.deleteMessageFromPostbox(
        this.msgContent.Content_Data[0].content_id
      );
      try {
        if (result.status === "success") {
          this.showErrorMsg = false;
          // @ts-expect-error 2571
          this.getMessageList();
          this.$emit("close");
        } else {
          this.showErrorMsg = true;
        }
      } catch (error) {
        console.error(error);
        this.showErrorMsg = true;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "../assets/scss/_colors.scss";
.content-info {
  color: $accent-color;
  margin: 0px;
}

h3 {
  margin-bottom: 5px;
}

.media-content {
  min-width: 250px;
  width: 40%;
  height: auto;
  max-height: 250px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease-in-out;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

.delete-msg-btn {
  background-color: $error-msg-color;
}

@media screen and (max-width: 700px) {
  .media-content {
    min-width: 200px;
  }
  .modal-footer {
    button {
      margin: 5px;
    }
  }
}
</style>
