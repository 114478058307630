<template>
  <div class="footer-wrapper">
    <a href="https://forevermind.de/"
      ><img
        src="../assets/logo_forevermind_white.png"
        alt="Forevermind Logo"
        class="logo"
    /></a>
    <div class="footer-links">
      <a href="https://forevermind.de/impressum/" target="_blank">
        {{ $t("siteFooter.imprintLink") }}</a
      >
      <a href="https://forevermind.de/datenschutz/" target="_blank">
        {{ $t("siteFooter.privacyLink") }}</a
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/scss/_colors.scss";
@import "../assets/scss/_typography.scss";

.footer-wrapper {
  width: 100%;
  min-height: 200px;
  background-image: url("../assets/forevermind_footer_bg.png");
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-left: 0;
  margin-right: 0;
  margin-top: 20px;
}
.logo {
  height: 50px;
}
.footer-links {
  color: $button-text-color;
  font-size: 1.2rem;
  display: inline-grid;
}
</style>
