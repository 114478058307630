import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-13f9e626"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card-container" }
const _hoisted_2 = { class: "msg-info-container" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "msg-title" }
const _hoisted_5 = { class: "msg-sender-container" }
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "msg-info" }
const _hoisted_9 = { class: "sender-name" }
const _hoisted_10 = { class: "card-footer" }
const _hoisted_11 = {
  key: 0,
  class: "do-not-open-label"
}
const _hoisted_12 = {
  key: 1,
  class: "do-not-open-msg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageModal = _resolveComponent("MessageModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "msg-type-icon",
        src: _ctx.msgTypeIcon,
        alt: "Message type icon",
        width: "150"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.msgData.Content_Data[0].title), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.blobImageUrl)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "sender-avatar",
            src: _ctx.blobImageUrl,
            alt: "Profile picture"
          }, null, 8, _hoisted_6))
        : (_openBlock(), _createElementBlock("img", {
            key: 1,
            class: "sender-avatar",
            src: `https://eu.ui-avatars.com/api/?name=${_ctx.msgData.Info_Data.Name}&size=250`,
            alt: "Profile picture",
            width: "150"
          }, null, 8, _hoisted_7)),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.msgData.Info_Data.Name), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      (_ctx.msgData.Content_Data[0].do_not_open_until == 'true')
        ? (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.$t("msgCard.doNotOpenLabel")), 1))
        : _createCommentVNode("", true),
      (_ctx.msgData.Content_Data[0].do_not_open_until == 'true')
        ? (_openBlock(), _createElementBlock("p", _hoisted_12, _toDisplayString(_ctx.msgData.Content_Data[0].open_until_message), 1))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showMessageModal = true))
      }, _toDisplayString(_ctx.$t("msgCard.openMsgButtonLabel")), 1),
      (_ctx.showMessageModal)
        ? (_openBlock(), _createBlock(_component_MessageModal, {
            key: 2,
            msgContent: _ctx.msgData,
            blobImageUrl: _ctx.blobImageUrl,
            msgContentLoaded: false,
            onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showMessageModal = false))
          }, null, 8, ["msgContent", "blobImageUrl"]))
        : _createCommentVNode("", true)
    ])
  ]))
}