<template>
  <div class="no-msgs-container" v-if="noMessages">
    <p>{{ $t("postbox.noMessagesLabel") }}</p>
  </div>
  <div class="msg-card-list-container" v-else>
    <div
      v-for="message in messageList"
      :key="message.Content_Data[0].content_id"
    >
      <MessageCard :msgData="message" />
    </div>
  </div>
  <ErrorStatusModal
    :title="statusTitleText"
    :message="statusModalText"
    v-if="showStatusModal"
    @close="showStatusModal = false"
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MessageCard from "./MessageCard.vue";
import ErrorStatusModal from "./ErrorStatusModal.vue";
import { PostboxAPI } from "@/api/api";
import { MessageData } from "@/types";
import router from "@/router";
const postboxAPI = new PostboxAPI();

export default defineComponent({
  name: "PostboxList",
  components: {
    MessageCard,
    ErrorStatusModal,
  },

  data() {
    return {
      messageList: [] as Array<MessageData>,
      noMessages: false,
      showStatusModal: false,
      statusTitleText: "",
      statusModalText: "",
    };
  },

  provide() {
    return {
      getMessageList: this.getMessageList,
    };
  },

  created() {
    this.getMessageList();
  },

  methods: {
    async getMessageList() {
      if (localStorage.getItem("token")) {
        try {
          const response = await postboxAPI.getContentList();
          if (response.status === "success" && !response.data.length) {
            this.noMessages = true;
          } else if (response.status === "success") {
            this.messageList.length = 0;
            response.data.forEach((msg) => {
              this.getMessageData(msg.content_ids);
            });
          }
        } catch (error) {
          this.showStatusModal = true;
          this.statusTitleText = this.$t("errorMsgs.errorTitle");
          this.statusModalText = this.$t("errorMsgs.genericErrorMessage");
        }
      } else {
        router.push("/login");
      }
    },
    async getMessageData(content_id: string) {
      try {
        const response = await postboxAPI.getContentDetail(content_id);
        if (response.status === "success") {
          this.messageList.push(response.data);
        }
      } catch (error) {
        console.error(error);
        this.showStatusModal = true;
        this.statusTitleText = this.$t("errorMsgs.errorTitle");
        this.statusModalText = this.$t("errorMsgs.genericErrorMessage");
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.msg-card-list-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  padding-bottom: 50px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
  grid-template-rows: repeat(auto-fit, minmax(180px, max-content));
}

@media screen and (max-width: 1200px) {
  .msg-card-list-container {
    grid-template-rows: max-content;
  }
}
</style>
